<template>
    <CSidebar position="fixed" class="sidebar_nav">
        <CSidebarBrand class="mb-3" @click="this.$router.push('/')">
            <CRow
                class="col-12 clickable align-content-center justify-content-center align-items-center">
                <img
                    alt="Coherent logo"
                    class="sidebar-brand-full"
                    style="height: 100px; width: 100px"
                    src="/images/elephant-circle.svg" />
            </CRow>
        </CSidebarBrand>

        <CSidebarNav class="sidebar_links">
            <template v-for="item in navigationItems">
                <SidebarNavItem :item="item" />
            </template>
        </CSidebarNav>
    </CSidebar>
</template>

<script>
import axios from 'axios';
import { SERVICE_URL } from '@/serviceResolver';
import _nav from '../_nav';
import SidebarNavItem from './SidebarNavItem.vue';

export default {
    name: 'AppSidebar',
    components: {
        SidebarNavItem,
    },
    data() {
        return {
            merchant: null,
            navigationItems: _nav.filter((it) =>
                it.name !== 'Appointments' && it.name !== 'Onboarding'
            ),
        };
    },
    computed: {},
    mounted() {
        axios.get(`${SERVICE_URL}/user`).then((userResponse) => {
            let updatedNavList = _nav;
            let merchantData = userResponse.data.user.merchant;
            // Filtering out appointments from the navigation list for all merchants other than the following.
            let appointmentsShowForMerchants = [
                'aecbac00-9f82-4cc1-865f-b4de12f6ba2e',
                '618536d7-1bf2-4a58-85db-3ba419e9099a',
                'eed9766b-ab1d-476e-a460-44798e8723be',
            ];

            if (merchantData) {
                if (merchantData.onboarded) {
                    updatedNavList = updatedNavList.filter(
                        (it) => it.name !== 'Onboarding',
                    );
                }

                if (!appointmentsShowForMerchants.includes(merchantData.uuid)) {
                    updatedNavList = updatedNavList.filter(
                        (it) => it.name !== 'Appointments',
                    );
                }
            }

            this.navigationItems = updatedNavList;
            this.merchant = merchantData;
        });
    },
};
</script>

<style lang="scss">
.sidebar_nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar_links {
    font-family: Inter !important;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-items: center;
    .nav_item {
        display: flex;
        align-items: center;
        justify-content: start;
        color: #747a80;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0;
        width: 100%;

        .nav_item_icon {
            // margin-right: 10px;
            width: 30%;
            filter: invert(50%);
        }
    }
    .nav_item_active {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }

    :hover {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }
}
</style>
