<template>
    <RouterLink :to="item.to" custom>
        <template v-slot="{ href, navigate, isActive }">
            <CNavItem
                :class="isActive ? `nav_item nav_item_active` : `nav_item`"
                ref="href"
                :active="isActive"
                @click="navigate">
                <font-awesome-icon
                    :icon="item.faIcon"
                    size="lg"
                    class="label-icon nav_item_icon" />
                <div>{{ item.name }}</div>
                <CBadge
                    v-if="item.badge"
                    :color="item.badge.color"
                    class="ms-auto"
                    >{{ item.badge.text }}</CBadge
                >
            </CNavItem>
        </template>
    </RouterLink>
</template>

<script>
export default {
    name: 'SidebarNavItem',
    props: ['item'],
};
</script>
